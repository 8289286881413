const zhCT = {
    user: {
        "language": "語言"
    },
    "store_name": "門店",
    "type_id_2": "手辦尺寸",
    "type_id_3": "拍攝姿勢",
    "type_id_4": "額外攜帶",
    "type_id_5": "額外攜帶",
    "total_price": "價格",
    "total": "合計",
    "submit_tip": "點擊登錄提交等待排隊",
    "mobile_form_tip": "或填寫手機姓名登記",
    "title": "拍照排隊",
    "err_tip_page": "連結參數錯誤，無法獲取到門店資訊",
    "pl_chose_goods": "請先選擇手辦尺寸",
    "pl_chose_attr": "請先選擇拍攝姿勢",
    "success_queue": "排隊成功",
    "fail_queue": "排隊失敗",
    "email": "郵箱",
    "mobile": "手機",
    "InternationalTelephoneAreaCode": "國際電話區號",
    "name": "姓名",
    "submit": "提交",
    "mobile_empty": "手機號不能为空",
    "name_empty": "姓名不能为空",
    "email_empty": "郵箱不能为空",
    "tip": "以上為單人拍照參考價格，詳細可與店員諮詢",
    "user_service_agreement": "速哇用戶服務協議",
    "user_privacy_agreement": "速哇隱私政策",
    "agree_tip": "請閱讀並同意協議",
    "queue_success": "排隊成功",
    "your_queue_number_is": "您的排隊號碼是",
    "Please_wait_for_the_number_to_be_called": "請等待叫號",
    "Number": "號",
    "go_back": "返回",
    "user_phone_is_empty": "手機號不能为空",
    "user_can_not_be_empty": "姓名不能为空",
    "store_is_empty": "門店不能为空",
    "calculate_total_amount_error": "計算總金額錯誤",
    "create_user_error": "創建用戶錯誤",
    "You_have_registered_please_wait": "您已經登記過了，請等待",
    "server_error": "服務器開小差了，請稍後再試",
    "name_required": "名稱是必填項",
    "mobile_required": "手機是必填項",
    "站姿": "站姿",
    "宠物+站姿": "寵物+站姿",
    "弯腰/半蹲/坐椅": "彎腰/半蹲/坐椅",
    "全蹲/坐地": "全蹲/坐地",
    "宠物": "寵物",
    "道具": "道具",
    'loading': '載入中',
    '加载中...': '載入中...',
    "agree_tip_prefix": "我已閱讀並同意",
    "queue_buy_type": "購買類型",
    "queue_price_tip": "以上為單人拍照參考價格，詳細可與店員諮詢",
    "birthday": "生日",
    "手办": "手辦",
    "3D相册": "3D相冊",
    "体验3D相册": "體驗3D相冊",
    "元": "元",
    "合计：": "合計：",
    "您前面还有": "您前面還有",
    "人请等待叫号": "人請等待叫號",
    "返回": "返回",
    "排队成功": "排隊成功",
    "3D摄影": "3D攝影",
    "排队": "排隊",
    "登录": "登錄",
    "客户手机号和客户编号不能同时为空": "客户手機號碼與客戶編號不能同時為空",
    "详情": "詳情",
    "modeling_completed": "建模完成",
    "人": "人",
    "3D相册制作中": "3D相冊製作中",
    "3D相册体验中": "3D相冊體驗中",
    "3D相册体验结束": "3D相冊體驗結束",
    "您购买的3D相册正在精修中！": "您所購買的3D相冊正在精修中！",
    "请求失败，点击重新加载": "請求失敗，點擊重新加載",
    "没有更多了": "沒有更多了",
    "查看3D相册": "查看3D相冊",
    "倒计时": "倒數",
    "天": "天",
    "待修模":"待修模",
    "修模中":"修模中",
    "待打印":"待列印",
    "待建模":"待建模",
    "打印中":"列印中",
    "未提交":"未提交",
    "快递中":"快遞中",
    "称净重":"稱淨重",
    "建模中":"建模中",
    "3D相册已创建":"3D相冊已創建",
    "后道工序处理":"後道工序處理",
    "后道工序处理中":"後道工序處理中",
    "订单信息": "訂單資訊",
    "订单详情": "訂單詳情",
    "订单ID":"訂單ID",
    "拍摄时间":"拍攝時間",
    "复制":"複製",
    "重打":"重打",
    "加打":"加打",
    "我的订单":"我的訂單",
    "订单":"訂單",
    "首页":"首頁",
    "订单记录": "訂單記錄",
    "联系门店": "聯繫門店",
    "拍摄门店": "拍攝門店",
    "费用合计": "費用合計",
    "复制成功": "複製成功",
    "复制失败": "複製失敗",
    "保存成功": "保存成功",
    "暂无快递信息": "暫無快遞信息",
    "退出登录中...": "退出登錄中...",

    "渲染中...": "渲染中...",
    "数据加载中...": "數據加載中...",
    "故障模型，请联系门店处理": "故障模型，請聯繫門店處理",
    "3D相册体验已结束": "3D相冊體驗已結束",
    "您购买的3D相册正在精修中，请稍候！": "您購買的3D相冊正在精修中，請稍後",
    "您未购买3D相册": "您未購買3D相冊",
    "体验还剩": "體驗還剩",
    "模型问题反馈": "模型問題反饋",
    "我要认领": "我要認領",
    "纯色背景": "純色背景",
    "已选择": "已選擇",
    "主题背景": "主題背景",

    "复位": "復位",
    "保存": "保存",
    "旋转": "旋转",
    "停止": "停止",
    "换背景": "换背景",
    "收起": "收起",
    "展开": "展開",
    "体验AR": "體驗AR",

    "我的":"我的",
    "关于我们":"關於我們",
    "语言":"語言",

    "头像":"頭像",
    "账号":"賬號",
    "性别":"性別",
    "退出登录":"退出登錄",
    "确认退出登录？":"確認退出登錄？",
    "确认退出":"確認退出",
    "我再想想":"我再想想",
    "个人中心":"個人中心",
    "请登录":"請登錄",
    "删除订单":"刪除訂單",
    "取消":"取消",

    "退出AR":"退出AR",
}
export default zhCT