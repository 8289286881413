<template>
    <div class="container" ref="container">
      <model-viewer 
        id="model-viewer"
        :src="testModel"
        tone-mapping="commerce" 
        antialias="true"
        shadow-intensity="0"
        ar 
        xr-environment
        camera-controls
        auto-rotate 
        style="width: 100%; height: 70vh;"
        scale="0.006 0.006 0.006"
        >
      </model-viewer>
      <div id="error" class="hide">AR is not supported on this device</div>
    </div>
  </template>
  
  <script setup lang="ts">
  import '@google/model-viewer';
  import { showFailToast } from 'vant';
  import UAParser from 'ua-parser-js';
  const container = ref(null)
  const emit = defineEmits([
    "backTo"
  ]);
  const testModel = ref("https://suwa3d-test.oss-cn-shanghai.aliyuncs.com/glbs/3d/151322.glb")
  const props = defineProps({
    modelFile: { type: String, default: () => "", },
    pid: { type: Number, default: 0 },
  });
  
  // 监听组件销毁事件
  onBeforeUnmount(() => {
    container.value.remove()
  });
  
  watch(() => props.modelFile, (val, oldVal) => {
    if (val) {
      isAr();
    }
  }, { immediate: true })
  
  const init = () => {
    const modelViewer = document.querySelector('model-viewer');
    console.log("modelViewer",modelViewer)
    modelViewer.addEventListener('load', (event) => {
      modelViewer.activateAR();
    });
    modelViewer.addEventListener('ar-status', (event) => {
      if(event.detail.status === 'failed'){
        const error = document.querySelector("#error");
        error.classList.remove('hide');
        error.addEventListener('transitionend',(event) => {
          error.classList.add('hide');
        });
      }
    });
  }
  
  const isAr = () => {
    const modelViewer = document.querySelector('model-viewer');
  
    const uaParser = new UAParser();
    const userAgent = uaParser.getResult();
  
    console.log('Browser:', userAgent.browser.name);
    // console.log('OS:', userAgent.os.name, userAgent.os.version);
    // console.log('Device:', userAgent.device.model);
    // if(userAgent.browser.name == "Chrome" || userAgent.browser.name == "Edge" || userAgent.browser.name == "Mobile Safari") {
    //   console.log('AR is supported!');
      init();
    // }else {
    //   showFailToast('抱歉，当前浏览器不支持AR,请切换谷歌或者edge浏览器。');
    // }
  }
  
  </script>
  
  <style lang="less" scoped>
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    margin: 0px;
  }
  model-viewer::part(default-ar-button){
    top: 15px;
    left: 25px;
    right: 0;
    bottom: 0;
  }
  #error {
      background-color: #ffffffdd;
      border-radius: 16px;
      padding: 16px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      transition: opacity 0.3s;
    }
    #error.hide {
      opacity: 0;
      visibility: hidden;
      transition: visibility 2s, opacity 1s 1s;
    }
  </style>